import React, { useMemo } from 'react';

import { GrafanaTheme2, PluginExtensionLink, RawTimeRange, TimeRange } from '@grafana/data';
import {
  GetPluginExtensionsOptions,
  getPluginLinkExtensions,
  usePluginLinks as usePluginLinksOriginal,
} from '@grafana/runtime';
import { LinkButton, Text, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';
import { Label, MatchType } from 'grafana-ml-common/types';

import { EmptyInvestigationsOptions, MultiLabel } from '../types';

interface EmptyPanelProps {
  timeRange: TimeRange;
  labels: MultiLabel[];
  emptyInvestigationOptions: EmptyInvestigationsOptions | undefined;
}

// `usePluginLinks()` is only available in Grafana>=11.1.0, so we have a fallback for older versions
const usePluginLinks = usePluginLinksOriginal !== undefined ? usePluginLinksOriginal : useExtensionLinks;

// Convert a set of multilabels, where the value of each label is an array
// of possible matches, into a label suitable for a Sift investigation input.
// This means converting labels where the values array has length > 0
// into regexp matchers.
function convertMultiLabels(labels: MultiLabel[]): Label[] {
  return labels
    .filter(({ value }) => value.length > 0)
    .map(({ name, value }) => ({
      name,
      type: value.length === 1 ? MatchType.Equal : MatchType.Regexp,
      value: value.length === 1 ? value[0] : `(${value.join('|')})`,
    }));
}

interface RunInvestigationContext {
  timeRange: RawTimeRange;
  labels: Label[];
}

// A panel shown when there were no matching investigations or analyses found.
//
// It includes a button to run a new investigation using the labels that were
// searched for.
export function EmptyPanel({ timeRange, labels, emptyInvestigationOptions }: EmptyPanelProps) {
  const styles = useStyles2(getStyles);
  const context: RunInvestigationContext = { timeRange, labels: convertMultiLabels(labels) };
  const { links } = usePluginLinks({
    extensionPointId: 'plugins/grafana-sift-panel/run-investigation',
    context,
  });
  const createInvestigationButton =
    links.length > 0 ? <LinkButton onClick={links[0].onClick}>Create a new investigation</LinkButton> : null;

  const showButton = (emptyInvestigationOptions?.showCreateButton ?? true) && links.length > 0;
  const message =
    emptyInvestigationOptions?.message ??
    `No matching investigations in the given time range.

Try changing the time range or labels` + (showButton ? ', or...' : '.');

  return (
    <div className={styles.empty}>
      <div className={styles.emptyMessage}>
        <Text element="p">{message}</Text>
      </div>
      {showButton && <div>{createInvestigationButton}</div>}
    </div>
  );
}

function getStyles(theme: GrafanaTheme2) {
  return {
    empty: css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      gap: ${theme.spacing.x1};
    `,
    emptyMessage: css`
      white-space: pre-wrap;
    `,
  };
}

// Fallback hook for Grafana versions >10.2 <11.1
function useExtensionLinks({ context, extensionPointId }: GetPluginExtensionsOptions): {
  links: PluginExtensionLink[];
  isLoading: boolean;
} {
  const { extensions } = useMemo(
    () =>
      getPluginLinkExtensions({
        extensionPointId,
        context,
      }),
    [context, extensionPointId]
  );

  return {
    links: extensions,
    isLoading: false,
  };
}
