import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

import { Label, MatchType } from '../types';
import { LabelField } from './LabelField';

interface LabelsProps {
  values: Label[];
  validation: LabelValidation;
  previewMessage?: string[];
  showErrors: boolean;
  onChange: (values: Label[]) => void;
  onStartChange?: () => void;
  onEndChange?: () => void;
  allowNonEquality?: boolean;
}

export interface LabelValidation {
  valid: boolean; // all labels are valid
  messages: string[]; // messages for labels that are not valid
}

export const Labels = ({
  values,
  validation,
  previewMessage,
  showErrors,
  onChange,
  onStartChange,
  onEndChange,
  allowNonEquality,
}: LabelsProps) => {
  const styles = useStyles2(getStyles);

  return (
    <div className={styles.container}>
      {values.map((label, i) => (
        <div key={`field${i}`} className={styles.labelRow}>
          <LabelField
            name={label.name}
            value={label.value}
            options={label.options}
            type={label.type ?? MatchType.Equal}
            allowNonEquality={allowNonEquality}
            errorMessage={showErrors ? validation.messages[i] : ''}
            previewMessage={previewMessage ? previewMessage[i] : ''}
            onStartChange={onStartChange}
            onEndChange={onEndChange}
            onChange={(name, value, type) => {
              const changed = [...values];
              changed[i].name = name;
              changed[i].value = value;
              changed[i].type = type;
              onChange(changed);
            }}
            onRemove={() => {
              if (typeof onStartChange === 'function') {
                onStartChange();
              }
              setTimeout(() => {
                const changed = [...values];
                changed.splice(i, 1);
                onChange(changed);
                if (typeof onEndChange === 'function') {
                  onEndChange();
                }
              }, 20);
            }}
          />
        </div>
      ))}
      <Button
        size="sm"
        variant="secondary"
        className={styles.addLabelButton}
        disabled={values.length > 0 && values[values.length - 1].name === ''}
        onClick={() => onChange([...values, { name: '', value: '' }])}
      >
        + Add label
      </Button>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  container: css`
    display: flex;
    flex-direction: column;
  `,
  labelRow: css`
    display: flex;
    gap: 8px;
    padding: 4px 0px;
  `,

  labelRowDim: css`
    display: flex;
    gap: 8px;
    padding: 4px 0px;
    opacity: 0.5;
  `,
  input: css`
    display: inline-block;
    flex-grow: 1;
    padding: 8px;
    background-color: ${theme.colors.background.secondary};
  `,
  itemsPerPage: css`
    width: 70px;
  `,
  addLabelButton: css`
    width: 100px;
    margin: 8px 28px 0px auto;
  `,
});
