import React, { useCallback, useEffect, useRef } from 'react';

import { DataSourceApi, EventBusSrv, TimeRange } from '@grafana/data';
import { AngularComponent, getAngularLoader } from '@grafana/runtime';
import { DataQuery } from '@grafana/schema';
import { Icon } from '@grafana/ui';

import { css, cx } from '@emotion/css';

interface Props {
  dataSource: DataSourceApi;
  timeRange: TimeRange;
  onChange: (q: DataQuery) => void;
  query: DataQuery;
  canToggleEditorModes?: boolean;
}

export const AngularQueryEditor: React.FC<Props> = ({
  dataSource,
  canToggleEditorModes = false,
  timeRange,
  query,
  onChange,
}) => {
  const divRef = useRef<HTMLDivElement>(null);
  const queryEditorRef = useRef<AngularComponent>(undefined);
  const queryRef = useRef<DataQuery>({ ...query });
  const toggleEditorMode = useCallback(() => {
    queryEditorRef.current?.getScope().ctrl.toggleEditorMode();
  }, []);

  const scopeRef = useRef({
    datasource: dataSource,
    target: queryRef.current,
    dashboard: {},
    refresh: () => {
      onChange({
        ...query,
        ...queryRef.current,
      });
    },
    render: () => () => console.info('legacy render function called, it does nothing'),
    panel: { targets: [queryRef.current] },
    events: new EventBusSrv(),
    range: timeRange,
  });

  useEffect(() => {
    const loader = getAngularLoader();
    const template = '<plugin-component type="query-ctrl" />';
    const scope = { ctrl: scopeRef.current };

    queryEditorRef.current = loader.load(divRef.current, scope, template);

    return () => {
      if (queryEditorRef.current != null) {
        queryEditorRef.current?.destroy();
        queryEditorRef.current = undefined;
      }
    };
  }, []);

  return (
    <div className={styles.queryContainer}>
      <div className={styles.queryWrapper} ref={divRef} />
      {canToggleEditorModes ? (
        <div className="gf-form">
          <button aria-label="Edit mode button" className={styles.toggleButton} onClick={toggleEditorMode}>
            <Icon name="pen" />
          </button>
        </div>
      ) : null}
    </div>
  );
};

const styles = {
  queryContainer: css`
    display: flex;
    flex-shrink: 0;
  `,
  queryWrapper: css`
    flex-grow: 1;
  `,
  toggleButton: cx(
    css`
      margin-right: 0;
    `,
    'gf-form-label',
    'gf-form-label--btn'
  ),
};

export const isDataSourceWithAngularQueryEditor = (dataSource: DataSourceApi): boolean => {
  return dataSource.components?.QueryCtrl !== undefined;
};
